.root {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.starOne {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999;
  left: -9.32px;
}

.starTwo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check {
  position: absolute;
}
