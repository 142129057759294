@import '../../../styles/propertySets.css';

@keyframes rotateUp {
  from {
    transform: rotateX(180deg);
  }

  to {
    transform: rotateX(0);
  }
}
.root {
  position: relative;
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.chevronOpen {
  transform: rotateX(180deg);
  transition: transform 0.5s;
}
.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  /** marketplaceSmallFontStyles */
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
/** end */
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  color: var(--matterColor);
  font-family: 'Greycliff';
    font-size: 24px;
  
    line-height: 28px;
    letter-spacing: -0.5px;
      font-weight:800;
  font-size: 20px;
}

.labelSelected {
  color: var(--marketplaceColor);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 12px;
    margin-top: 8px;
  }
}

.plain {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

.chevron {
  animation-name: rotateUp;
  animation-duration: 0.5s;
}
