@import '../../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  /**marketplaceButtonStylesPrimary*/  
  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }

    background-color: var(--fave-color-input);
    color: var(--fave-color-primary-default);

    &:hover,
    &:focus {
      background-color: var(--fave-color-border);
     
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
/** end */;
  /**marketplaceSearchFilterLabelFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 13px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 20px;
    }
/** end */

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  /**marketplaceButtonStyles*/  
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
/** end */
  /**marketplaceSearchFilterLabelFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 13px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 20px;
    }
/** end */
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
